import {useState, useCallback, MouseEvent, ChangeEvent} from "react";
import {useAppSelector} from "../../store/store";
import {fCurrency} from "../../utils/formatNumber";
// MUI
import {
    Grid,
    Container,
    ToggleButton,
    ToggleButtonGroup,
    Switch,
    FormGroup,
    FormControlLabel
} from '@mui/material';
// Components
import {Page} from "../../layouts/components";
import ForecastConfigBar from "./ForecastConfigBar";
import {availableCapitalSelector} from "../../store/capitalBudget/selectors/Reports/availableCapitalReport";
import {HorizontalTable} from "../../components";
import {styled} from "@mui/material/styles";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    "& .MuiToggleButtonGroup-grouped": {
        "&.MuiToggleButton-root": {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main
        },
        "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },

    }
}));

export default function AvailableCapitalSummary() {
    const dataLoading = useAppSelector(state => state.capitalBudget.loading);
    const calculating = useAppSelector(state => state.capitalBudget.calculating);
    const {data, rows} = useAppSelector(state => availableCapitalSelector(state));

    const [millions, setMillions] = useState<boolean>(true);

    const toggleMillions = useCallback((event: MouseEvent<HTMLElement>, value: boolean) => {
        setMillions(value as boolean);
    }, [setMillions])

    const [undrawn, setUndrawn] = useState<boolean>(false);

    const toggleUndrawn = useCallback((event: ChangeEvent<HTMLInputElement>, value: boolean) => {
        setUndrawn(value as boolean);
    }, [setUndrawn])

    const formatCurrency = useCallback((value: number) => {
        return fCurrency(value, 2, millions)
    }, [millions]);

    return (
        <Page title="Metrics - Capital Budget">
            <Container maxWidth={false} sx={{maxWidth: '100%', height: 1, display: 'flex', flexDirection: 'row'}}>
                <Grid container direction='column' sx={{height: 1, flex: 1}}>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item sm={11}>
                            <ForecastConfigBar title={'Available Capital Summary'} showFundFilter={false}
                                               showMillions={false}/>
                        </Grid>
                        <Grid item container sm={1} justifyContent={'flex-end'} alignItems={'flex-end'} padding={0.5}
                              sx={{pb: 2}}>
                            <StyledToggleButtonGroup
                                fullWidth
                                disabled={(dataLoading || calculating)}
                                value={millions}
                                exclusive
                                onChange={toggleMillions}
                                size='small'
                                sx={{bgcolor: 'common.white'}}
                            >
                                <ToggleButton fullWidth value={false}>$</ToggleButton>
                                <ToggleButton fullWidth value={true}>$m</ToggleButton>
                            </StyledToggleButtonGroup>
                        </Grid>
                        <Grid container item sm={12} direction="row" justifyContent={'flex-end'} alignItems={'center'}>
                            {/*<Typography>*/}
                            {/*    Toggle Undrawn Commitments*/}
                            {/*</Typography>*/}
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={undrawn} onChange={toggleUndrawn}/>} label={'Undrawn Commit. Inclusion'} />
                            </FormGroup>
                            {/*<Switch*/}
                            {/*    */}
                            {/*/>*/}
                        </Grid>
                    </Grid>
                    <Grid item sx={{flex: 1, overflowY: 'hidden', width: '100%', py: 1}}>
                        <HorizontalTable
                            rows={rows.map(row => ({
                                ...row,
                                formatter: formatCurrency,
                                id: undrawn ? `undrawn${row.id}` : row.id
                            }))}
                            data={(data) ? data : []}
                            loading={dataLoading || calculating}

                            selectableCell
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}