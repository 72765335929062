import {ForecastPeriod} from "../../../../../types/capitalBudgetTypes";
import {LoanTags} from "../../../../../types/capitalBudgetEnums";
import {addValues} from "../../../../../utils/mathUtil";
import {checkInPeriod} from "../../../../../utils/DateUtils";
import {AvailableCaptFundData} from "./index";

export const handleLoanBook = (period: ForecastPeriod, fundValues: Map<string, AvailableCaptFundData>) => {
    // Handle Loan Book
    period.book.forEach((loan) => {
        const fund: AvailableCaptFundData | null = fundValues.get(loan.fund) || null;

        if (fund) {
            loan.tags.forEach(tag => {
                switch (tag) {
                    case LoanTags.REPAYMENT:
                        fund.contractualRepayments = addValues(fund.contractualRepayments, loan.value);
                        break;

                    case LoanTags.NEW_LOAN:
                        fund.newCommitments = addValues(fund.newCommitments, -loan.value);
                        break;

                    case LoanTags.OFFSET_EXTENSION:
                        fund.extensionOffsets = addValues(fund.extensionOffsets, -loan.value);
                        break;

                    case LoanTags.EARLY_REPAYMENT:
                        fund.earlyRepayments = addValues(fund.earlyRepayments, loan.value);
                        break;

                    case LoanTags.SELLDOWN:
                        const sellAmount = (loan.selldowns || []).reduce((total, selldown) => {
                            if (checkInPeriod(selldown.date, period)) {
                                total = addValues(total, selldown.amount);
                            }
                            return total;
                        }, 0);
                        fund.selldowns = addValues(fund.selldowns, sellAmount);
                        break;

                    case LoanTags.TRANSFER_OUT:
                        const transfersOutAmount = (loan.transfersOut || []).reduce((total, transfer) => {
                            if (checkInPeriod(transfer.transferDate, period)) {
                                total = addValues(total, transfer.amount);
                            }
                            return total;
                        }, 0);
                        fund.transfersOut = addValues(fund.transfersOut, transfersOutAmount);
                        break

                    case LoanTags.TRANSFER_IN:
                        const transfersInAmount = (loan.transfersIn || []).reduce((total, transfer) => {
                            if (checkInPeriod(transfer.transferDate, period)) {
                                total = addValues(total, transfer.amount);
                            }
                            return total;
                        }, 0)
                        fund.transfersIn = addValues(fund.transfersIn, -transfersInAmount);
                        break

                    case LoanTags.EXTENSION:
                        fund.extensions = addValues(fund.extensions, loan.value);
                        break;

                    case LoanTags.OFFSET_EARLY:
                        fund.earlyRepaymentsOffset = addValues(fund.earlyRepaymentsOffset, -loan.value);
                        break;

                    case LoanTags.OFFSET_SELLDOWN:
                        const selldownOffsetAmount = (loan.selldowns || []).reduce((total, selldown) => {
                            total = addValues(total, selldown.amount);
                            return total;
                        }, 0);
                        fund.selldownsOffset = addValues(fund.selldownsOffset, -selldownOffsetAmount);
                        break;

                    case LoanTags.OFFSET_TRANSFER_OUT:
                        const transfersOutOffset = (loan.transfersOut || []).reduce((total, t) => {
                            total = addValues(total, t.amount);
                            return total;
                        }, 0);
                        fund.transfersOutOffset = addValues(fund.transfersOutOffset, -transfersOutOffset)
                        break;

                    case LoanTags.OFFSET_TRANSFER_IN:
                        const transfersInOffset = (loan.transfersIn || []).reduce((total, t) => {
                            total = addValues(total, t.amount);
                            return total;
                        }, 0);
                        fund.transfersInOffset = addValues(fund.transfersInOffset, transfersInOffset)
                        break;

                    case LoanTags.ACTIVE:
                        if (loan.updatedUndrawn > 0) {
                            fund.undrawnCommitments = addValues(fund.undrawnCommitments, loan.updatedUndrawn);
                        }
                        break;

                    default:
                        break;
                }
            })

        }

    });
}