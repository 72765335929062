import _ from 'lodash';
import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../store";
import {HorizontalTableColumn, HorizontalTableRow} from "../../../../../components";
import {ForecastPeriod} from "../../../../../types/capitalBudgetTypes";
import {addArrayValues, addValues} from "../../../../../utils/mathUtil";
import {handleOtherAdjustments} from "./handleOtherAdjustments";
import {handleCapitalMovements} from "./handleCapitalMovements";
import {handleLoanBook} from "./handleLoanBook";
import {CellType} from "../../../../../types/InputTypes";

export interface AvailableCapitalReportColumn extends HorizontalTableColumn {
    data: Map<string, AvailableCaptFundData>,

    [x: string]: any,
}

export interface AvailableCaptFundData {
    label: string,

    startingAvailableCapital: number,
    contractualRepayments: number,
    // ---
    contractualSurplusDeficit: number,
    // ---
    newCommitments: number,
    extensionOffsets: number,
    earlyRepayments: number,

    selldowns: number,
    transfersOut: number,
    transfersIn: number,

    subscriptions: number,
    redemptions: number,

    extensions: number,
    earlyRepaymentsOffset: number,

    selldownsOffset: number,
    transfersOutOffset: number,
    transfersInOffset: number,

    debtFacilities: number,
    // ---
    adjustments: number,
    // ---
    periodAdjustments: number,
    // ---
    ongoingAdjustments: number,
    // ---
    surplusDeficit: number,

    undrawnCommitments: number,

    surplusDeficitExclUndrawn: number,

    allocations: Array<{ fund: string, allocation: number }> | null,
    type: 'UNDERLYING' | 'FEEDER',
}

const retrieveOpeningCapitalBalances = createSelector(
    (state: RootState) => state.capitalBudget?.forecastData?.funds || [],
    (state: RootState) => state.capitalBudget.externalData?.accountBalance?.balances || [],
    (funds, balances) => {
        if (!funds || !balances) return null;
        // map balances
        let fundBalances: Array<{
            label: string,
            balance: number,
            priority: number | null,
            type: 'UNDERLYING' | 'FEEDER',
            allocations: Array<{ fund: string, allocation: number }> | null
        }> = []

        funds.forEach(fund => {
            const balance = balances.find(b => {
                if (fund.label === b.fund) return b;
                if (fund.alternatives && fund.alternatives.includes(b.fund)) return b;
                return false;
            })

            fundBalances.push({
                label: fund.label,
                balance: balance?.capitalAvailability || 0,
                priority: fund.priority,
                type: fund.type,
                allocations: fund.allocations || null
            })
        })
        return fundBalances;
    }
)

const genericRow = {
    id: '',
    label: '',
    headSX: {
        minWidth: 100, height: 12, padding: 0.5, color: 'black', bgcolor: 'common.white', borderTop: 1,
        borderBottom: 1,
        borderBottomColor: 'grey.300',
        borderTopColor: 'white',
        fontWeight: 'bold',
        "&:hover": {},
    },
    sx: {
        padding: 0.5,
    },
    type: CellType.CURRENCY,
}

interface AvailableCapitalRow extends HorizontalTableRow {
    priority?: number | null
}

export const availableCapitalSelector = createSelector(
    (state: RootState) => state.capitalBudget.capitalBudget.budget,
    retrieveOpeningCapitalBalances,
    (budget, balances) => {
        if (!budget || !balances) return {data: [], rows: []};

        // Set base starting values
        const initialValues: Map<string, AvailableCaptFundData> = new Map();
        const fundsBalance: { [x: string]: number } = {};

        const underlyingRows: Array<AvailableCapitalRow> = [];
        const feederRows: Array<AvailableCapitalRow> = [];

        balances.forEach((balance) => {
            const fund = initialValues.get(balance.label) || {
                label: balance.label,
                startingAvailableCapital: balance.balance,
                contractualRepayments: 0,
                contractualSurplusDeficit: 0,
                newCommitments: 0,
                extensionOffsets: 0,
                earlyRepayments: 0,
                selldowns: 0,
                transfersOut: 0,
                transfersIn: 0,
                subscriptions: 0,
                redemptions: 0,
                extensions: 0,
                earlyRepaymentsOffset: 0,
                selldownsOffset: 0,
                transfersOutOffset: 0,
                transfersInOffset: 0,
                debtFacilities: 0,
                adjustments: 0,
                periodAdjustments: 0,
                ongoingAdjustments: 0,
                surplusDeficit: 0,
                undrawnCommitments: 0,
                surplusDeficitExclUndrawn: 0,
                allocations: balance.allocations,
                type: balance.type
            }

            initialValues.set(balance.label, fund);

            fundsBalance[balance.label] = 0;

            if (balance.type === 'UNDERLYING') underlyingRows.push({
                ...genericRow,
                id: balance.label,
                label: balance.label,
                priority: balance.priority || null
            });
            else feederRows.push({...genericRow, id: balance.label, label: balance.label});
        })

        let weeks = handlePeriodSetAvailCap(budget.weeks, initialValues, fundsBalance);
        let months = handlePeriodSetAvailCap(budget.months, initialValues, fundsBalance);

        weeks = extractAvailableCapital(weeks);
        months = extractAvailableCapital(months);

        const availableCapitalRows = [
            ...underlyingRows.sort((a, b) => {
                if (a.priority)
                    return b.priority ? a.priority - b.priority : -1;
                else return b.priority ? 1 : a.label.localeCompare(b.label)
            }),
            {
                id: 'underlying',
                label: 'Surplus/Deficit Ava. Cap.',
                // formatter: (value: number) => fCurrency(value, 2, true),
                headSX: {
                    minWidth: 80, height: 12, padding: 0.5, color: 'black', bgcolor: 'grey.400', borderTop: 1,
                    borderBottom: 1,
                    borderBottomColor: 'black',
                    borderTopColor: 'black',
                    fontWeight: 'bold',
                    "&:hover": {},
                },
                sx: {
                    bgcolor: 'grey.400', borderTop: 1,
                    borderBottom: 1,
                    borderBottomColor: 'black',
                    borderTopColor: 'black',
                    fontWeight: 'bold',
                    "&:hover": {},
                },
                type: CellType.CURRENCY
            },
            ...feederRows.sort((a, b) => a.label.localeCompare(b.label)),
            {
                id: 'feeder',
                label: 'Surplus/Deficit Ava. Cap.',
                headSX: {
                    minWidth: 150, height: 12, padding: 0.5, color: 'black', bgcolor: 'grey.400', borderTop: 1,
                    borderBottom: 1,
                    borderBottomColor: 'black',
                    borderTopColor: 'black',
                    fontWeight: 'bold'
                },
                sx: {
                    bgcolor: 'grey.400', borderTop: 1,
                    borderBottom: 1,
                    borderBottomColor: 'black',
                    borderTopColor: 'black',
                    fontWeight: 'bold'
                },
                type: CellType.CURRENCY
            },
            {
                id: 'total',
                label: 'Total Surplus/Deficit Ava. Cap.',
                headSX: {
                    minWidth: 80, height: 12, padding: 0.5, color: 'black', bgcolor: 'primary.light', borderTop: 1,
                    borderBottom: 1,
                    borderBottomColor: 'black',
                    borderTopColor: 'black',
                    fontWeight: 'bold'
                },
                sx: {
                    borderTop: 1,
                    borderBottom: 1,
                    borderBottomColor: 'black',
                    borderTopColor: 'black',
                    fontWeight: 'bold'
                },
                type: CellType.CURRENCY
            },
        ];

        weeks[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};
        months[0].sx = {borderLeft: 2, borderLeftColor: 'primary.main'};

        return {
            data: [
                ...weeks,
                ...months
            ],
            rows: availableCapitalRows
        }

    }
)

export const handlePeriodSetAvailCap = (periods: Array<ForecastPeriod>, initialFundValues: Map<string, AvailableCaptFundData>, fundsBalances: {
    [x: string]: number
}) => {
    let prevContractualSurplusDeficit = _.cloneDeep(fundsBalances);
    let ongoingAdjustments = _.cloneDeep(fundsBalances);

    return periods.reduce((results: Array<AvailableCapitalReportColumn>, period: ForecastPeriod, i) => {

        let periodResult = _.cloneDeep(initialFundValues);

        periodResult.forEach((fund, key) => {
            fund.ongoingAdjustments = ongoingAdjustments[key];
            if (i !== 0) fund.startingAvailableCapital = prevContractualSurplusDeficit[key];
        })

        // handle period
        periodResult = handleAvailCapPeriod(period, periodResult);

        periodResult.forEach((fund, key) => {
            ongoingAdjustments[key] = addValues(ongoingAdjustments[key], fund.periodAdjustments);

            prevContractualSurplusDeficit[key] = fund.contractualSurplusDeficit;
        })

        results.push({
            label: period.label,
            labelTwo: period.labelTwo,
            startDate: period.startDate,
            lastDate: period.lastDate,
            tooltip: period.tooltip,
            type: period.type,

            data: periodResult
        });

        return results;
    }, [])
}

export const handleAvailCapPeriod = (period: ForecastPeriod, fundValues: Map<string, AvailableCaptFundData>) => {

    handleLoanBook(period, fundValues);

    handleCapitalMovements(period, fundValues);

    handleOtherAdjustments(period, fundValues);

    fundValues.forEach((fund) => {
        fund.contractualSurplusDeficit = addValues(fund.contractualRepayments, fund.startingAvailableCapital);

        fund.periodAdjustments = addArrayValues([
            fund.newCommitments,
            fund.extensionOffsets,
            fund.earlyRepayments,
            fund.selldowns,
            fund.transfersOut,
            fund.transfersIn,
            fund.subscriptions,
            fund.redemptions,
            fund.extensions,
            fund.earlyRepaymentsOffset,
            fund.selldownsOffset,
            fund.transfersOutOffset,
            fund.transfersInOffset,
            fund.debtFacilities,
            fund.adjustments
        ]);

        fund.ongoingAdjustments = addArrayValues([fund.ongoingAdjustments, fund.periodAdjustments]);
        fund.surplusDeficit = addArrayValues([fund.contractualSurplusDeficit, fund.ongoingAdjustments]);

        fund.surplusDeficitExclUndrawn = addArrayValues([fund.surplusDeficit, fund.undrawnCommitments]);
    })

    return fundValues;
}

export const extractAvailableCapital = (periods: Array<AvailableCapitalReportColumn>) => {

    return periods.reduce((periods: Array<AvailableCapitalReportColumn>, period: AvailableCapitalReportColumn) => {
        const availableCap: { total: number, underlying: number, feeder: number, [x: string]: number } = {
            total: 0,
            underlying: 0,
            feeder: 0,
            undrawnunderlying: 0,
            undrawnfeeder: 0,
            undrawntotal: 0
        };

        period.data.forEach((fund, key) => {
            availableCap[key] = fund.surplusDeficit;
            availableCap[`undrawn${key}`] = fund.surplusDeficitExclUndrawn;
            availableCap.total = addValues(availableCap.total, fund.surplusDeficit);
            availableCap.undrawntotal = addValues(availableCap.undrawntotal, fund.surplusDeficitExclUndrawn);
            if (fund.type === 'UNDERLYING') {
                availableCap.underlying = addValues(availableCap.underlying, fund.surplusDeficit);
                availableCap.undrawnunderlying = addValues(availableCap.undrawnunderlying, fund.surplusDeficitExclUndrawn);
            }
            else {
                availableCap.feeder = addValues(availableCap.feeder, fund.surplusDeficit);
                availableCap.undrawnfeeder = addValues(availableCap.undrawnfeeder, fund.surplusDeficitExclUndrawn);
            }
        })

        periods.push({
            ...period,
            ...availableCap
        })

        return periods;

    }, []);
}