import {ForecastPeriod} from "../../../../../types/capitalBudgetTypes";
import {CapitalAction, CapitalTypes} from "../../../../../types/capitalBudgetEnums";
import {Capital} from "../../../../../types/forecastTypes";
import {addValues} from "../../../../../utils/mathUtil";
import {AvailableCaptFundData} from "./index";

export const handleCapitalMovements = (period: ForecastPeriod, fundValues: Map<string, AvailableCaptFundData>) => {

    period.capital.forEach(capital => {
        const fund: AvailableCaptFundData | null = fundValues.get(capital.fund) || null;

        if (fund) {
            distributeCapital(capital, fund, 1);

            if (capital.investorType === CapitalTypes.INVESTOR && fund.allocations) {
                fund.allocations.forEach(a => {
                    const holdingFund = fundValues.get(a.fund) || null;

                    if (holdingFund && holdingFund.type === 'UNDERLYING') {
                        distributeCapital(capital, holdingFund, a.allocation);
                    }
                })
            }
        }
    })
}

const distributeCapital = (capital: Capital, fund: AvailableCaptFundData, allocation: number) => {

    const amount = capital.amount * allocation;

    switch (capital.transactionType) {
        case CapitalAction.SUBSCRIPTION:
            fund.subscriptions = addValues(fund.subscriptions, amount);
            break;

        case CapitalAction.REDEMPTION:
            fund.redemptions = addValues(fund.redemptions, amount);
            break;

        case CapitalAction.COMMITMENT:
            fund.debtFacilities = addValues(fund.debtFacilities, amount);
            break;

        case CapitalAction.CANCELLATION:
            fund.debtFacilities = addValues(fund.debtFacilities, amount);
            break;

        default:
            break;
    }
}