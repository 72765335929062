import {ForecastPeriod} from "../../../../../types/capitalBudgetTypes";
import {addValues} from "../../../../../utils/mathUtil";
import {AvailableCaptFundData} from "./index";

export const handleOtherAdjustments = (period: ForecastPeriod, fundValues: Map<string, AvailableCaptFundData>) => {
    period.otherTransactions.forEach(transaction => {
        const fund = fundValues.get(transaction.fund) || null;

        if (fund) {
            switch (transaction.transactionType) {
                case 'ADJUSTMENT':
                    fund.adjustments = addValues(fund.adjustments, transaction.amount);
                    break;

                case 'FUND TRANSFER':
                    if (transaction.amount < 0) {
                        fund.transfersIn = addValues(fund.transfersIn, transaction.amount);
                    } else if (transaction.amount > 0) {
                        fund.transfersOut = addValues(fund.transfersOut, transaction.amount);
                    }
                    break;

                default:
                    break;
            }
        }
    })
}